import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/styles/variable_fonts.css';
import "@/assets/styles/custom.scss";
import "bootstrap"
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faStopwatch, faCalendarCheck, faListCheck, faClock, faDollar, faBookOpen, faFolder, faMusic, faStar, faPersonChalkboard, faQuoteLeft, faQuoteRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faNoteSticky, faUser } from "@fortawesome/free-regular-svg-icons"
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
/* add icons to the library */
library.add(faStopwatch, faCalendarCheck, faListCheck, faClock, faDollar, faBookOpen, faFolder, faMusic, faNoteSticky, faStar, faPersonChalkboard, faQuoteLeft, faQuoteRight, faCircleCheck, faUser, faFacebook);

// Register the FontAwesomeIcon globally
createApp(App)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon) // Register FontAwesomeIcon globally
    .mount('#app');
