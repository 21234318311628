import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'base-layout',
    component: () => import( /* webpackChunkName: "base-layout" */ '../layouts/BaseLayout.vue'),
    children: [{
      path: '',
      name: 'home',
      component: () => import( /* webpackChunkName: "home-view" */ '../views/HomeView.vue'),
    },
    {
      path: '/sign-up/',
      name: 'SignUpRedirect',
      component: () => import( /* webpackChunkName: "home-view" */ '../views/SignUpRedirect.vue'),
    },
    {
      path: '/login/',
      name: 'SignInRedirect',
      component: () => import( /* webpackChunkName: "home-view" */ '../views/SignInRedirect.vue'),
    },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

export default router
